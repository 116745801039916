import { useContext, useState, useCallback, useEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { CalendarEventIcon, CalendarPlusIcon, GlobeIcon, LayersIcon, PencilIcon, PeopleIcon, XSquareIcon, EnvolpeIcon, BoxArrowUpRight, InfoCircleIcon } from "../assets"
import { IEventSearchResultDocument, IEventContentModelDocument, IPresentersDocument, ISearchRequestFilterDocument, ISearchSortDocument, IEventModelDocument, IMiBranchModelDocument, IGroupCoachingTopicResultDocument, IGroupCoachingModelDocument } from "../open-api"
import { sortListBySorts, filterGridListItems, locationHashToObject, getQuarter } from "../services/helpers"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { GridDataFetch, IGridListItem, IGridState, GridUserInteractionStateKey, GridListItemValueType, IGridAction } from "../stores/grid-definitions"
import { gridReducer } from "../stores/grid-reducer"
import { IDefaultProps } from "./component-definitions"
import { Grid } from "./grid"
import { DefaultGridCellDisplay, GridActionCell } from "./grid-cell-displays"
import { IGroupCoachingEventStatus } from "./group-coaching"
import { Modal } from "./modal"
import { EventContentForm } from './event-content-form'
import { ISelectFieldOption, isSelectFieldOption } from "./forms"
import { EventForm, EventFormTab, EventStatus } from './event-form'
import { SquareDeleteIcon } from "./partials"
import { navigate, useLocation } from "@reach/router"
import { EventDuplicateForm } from "./event-duplicate-form"
import dayjs from "dayjs"
import { EVENT_CONTENT_TYPES, EVENT_TYPES, MINISTRY_PORTAL_ROOT_URL } from "../constants"
import { IFilter, ISort } from "../stores/api-actions"
import {EventInviteBlastsForm} from "./event-invite-blasts-form"
import { GroupCoachingForm } from "./group-coaching-form"
import { Path } from "./dashboard"
import { EventAddToMyCalendar } from "./event-add-to-my-calendar"

export const getEventUrl = (eventTypeId:string, eventContentId:string, eventId:string) => {
    return eventTypeId == EVENT_TYPES.GroupLearning.toString() ?
        `/group-learning/${eventContentId}/${eventId}`
        : 
        `/event-management/${eventId}`
}

export enum EventContentStatus {
    'draft' = 'draft',
    'activated' = 'activated'
}

enum Mode {
    'upcoming' = 'upcoming',
    'current' = 'current',
    '6mos' = '6mos',
    '12mos' = '12mos',
    'all' = 'all',
}

const allSort: ISort = {
    property: 'startTime',
    direction: 'DESC'
}

interface IEventManagementState {
    events: IEventSearchResultDocument[]
    selectedEventContent?: IEventContentModelDocument
    eventToEdit?: IEventModelDocument
    selectedRow?: IGridListItem
    mode: Mode
    presenters: IPresentersDocument[]
    eventFormTab?: EventFormTab
    eventTypeIds: EVENT_TYPES[]
    topics: IGroupCoachingTopicResultDocument[]
    topicTypes: { id: number, name: string }[]
}

// interface IEventManagementProps extends IDefaultProps {
//     eventTypeId?: EVENT_TYPES
//     headerText?: string
//     headerIcon?: any
// }


export const EventManagement = (props: IDefaultProps) => {

    const { PresentersApi, EventMgmtApi, GroupCoachingTopicApi } = useContext(AppActionContext)!
    const { activeBranches, currentUser, globalCommunityContext } = useContext(AppStateContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [state, setState] = useState<IEventManagementState>({
        events: [],
        mode: Mode.upcoming,
        presenters: [],
        eventTypeIds: [EVENT_TYPES.GroupLearning, EVENT_TYPES.Webinar, EVENT_TYPES.Workshop],
        topicTypes: [],
        topics: []
    })

    // Set the active community.
    // If current user is Super User, set to the selected global community context value.
    // if current user is NOT Super User, set to their default community (branchId)
    const [activeCommunity, setActiveCommunity] = useState<IMiBranchModelDocument>()

    useEffect(() => {
        if (globalCommunityContext) {
            setActiveCommunity(globalCommunityContext)
        } else {
            setActiveCommunity(undefined)
        }
        // 20220422 TB - Karla would like ADs to be able to see events for any community, not just there own.
        // if (currentUser?.bSuperUser) {
        //     if (globalCommunityContext) {
        //         setActiveCommunity(globalCommunityContext)
        //     } else {
        //         setActiveCommunity(undefined)
        //     }
        // }
        // else {
        //     setActiveCommunity(activeBranches.find(o => o.branchId === currentUser?.branchId))
        // }
    }, [globalCommunityContext])

    const fetchEvents = useCallback(async (mode: IEventManagementState['mode'], eventTypeIds: IEventManagementState['eventTypeIds']) => {
        console.log('fetchEvents()')
        console.log('fetchEvents > mode', mode)
        if (mode === Mode.all) {
            setState(_state => ({ ..._state, events: [] }))
            // Instead of fetching data here, it gets fetched via the 'dataSource' variable declaration further down this page.
        } else {
            console.log('fetchEvents() > getData')
            let { data: { data } } = await makeHttpRequestWithUi({
                request: EventMgmtApi.apiEventMgmtSimpleSearchGet(mode, eventTypeIds),
                disableSuccessToast: true,
                toastErrorMessage: 'There was a problem fetching the list of Event Management events.'
            })
            
            setState(_state => ({ ..._state, events: data || [] }))
        }
    }, [])

    const getGroupTitle = (item: IEventSearchResultDocument) => {
        const contentDate = <span>Q{getQuarter(item.eventContentMonth)}&nbsp;&nbsp;{item.eventContentYear}</span>;

        let eventType = item.eventType
        if (item.isSeries) {
            eventType = 'Series'
        }
        return <span>{contentDate} {`${eventType} - ${item.eventTypeId == EVENT_TYPES.GroupLearning ? `${item.branchAbbr?.trim()} - ` : ''} ${item.eventContentTitle}` || ''}  {!item.eventContentIsActive ? <span className='badge badge-info'>Draft</span> : null} {item.isLegacyEvent ? <span className='badge badge-danger'>Legacy</span> : null}</span>
    }

    const dataSource: GridDataFetch<IEventManagementState> = useCallback(async (queryState, _state) => {
        try {
            const data = await (async () => {
                if (_state.mode === Mode.all) {
                    // We have to type-coerce the filters and sorts since the OpenApi template generator doesn't support serializing complex URL query params
                    const filters = (queryState.filters ? JSON.stringify([...queryState.filters.filter(f => f.enabled && f.value !== null && f.value !== undefined)]) : undefined) as unknown as ISearchRequestFilterDocument[] | undefined
                    const sorts = (queryState.sorts ? JSON.stringify(queryState.sorts) : JSON.stringify([allSort])) as unknown as ISearchSortDocument[] | undefined
                    const { data: { data } } = await makeHttpRequestWithUi({
                        request: EventMgmtApi.apiEventMgmtGet(
                            (queryState.page - 1) * queryState.itemsPerPage,
                            queryState.itemsPerPage,
                            sorts,
                            filters
                        ),
                        disableSuccessToast: true,
                        toastErrorMessage: 'There was a problem fetching the list of events.'
                    })
                    return data
                } else {
                    return _state.events
                }
            })()

            if (queryState.sorts && _state.mode !== Mode.all) sortListBySorts(data || [], queryState.sorts)
            // Order the rows by Event Content date/month/title
            // Allow the inner records to still be sorted by grid sorts
            // https://missionincrease.atlassian.net/secure/RapidBoard.jspa?rapidView=1&projectKey=MA20&modal=detail&selectedIssue=MA20-2158&quickFilter=1&assignee=5c3575e0b4d5d75a3b51e59b
            if (_state.mode !== Mode.all) {
                data?.sort((a, b) => {
                    const aDate = dayjs().month(a.eventContentMonth).year(a.eventContentYear).startOf('month')
                    const bDate = dayjs().month(b.eventContentMonth).year(b.eventContentYear).startOf('month')
                    if (aDate.isBefore(bDate)) return 1
                    if (aDate.isAfter(bDate)) return -1
                    if (!a.eventContentTitle || !b.eventContentTitle) return 0
                    if (a.eventContentTitle > b.eventContentTitle) return 1
                    if (a.eventContentTitle < b.eventContentTitle) return -1
                    return 0
                })
            }

            let rows = (() => {
                if (_state.mode === Mode.all) {
                    return (data || []).map<IGridListItem>(item => ({
                        id: item.eventId.toString(),
                        values: {
                            ...item,
                            startTime: new Date(item.startTime),
                        },
                        hidden: !Boolean(item.eventId)
                    }))
                } else {
                    return (data || []).map<IGridListItem>(item => ({
                        id: item.eventId.toString(),
                        values: {
                            ...item,
                            startTime: new Date(item.startTime),
                        },
                        rowGroup: {
                            id: item.eventContentId.toString(),
                            title: getGroupTitle(item),
                            showEmptyRowGroup: true,
                            collapsed: true
                        },
                        hidden: !Boolean(item.eventId)
                    }))
                }
            })()
            
            if (queryState.filters && _state.mode !== Mode.all) rows = filterGridListItems(rows, queryState.filters)

            let count = (() => {
                if (_state.mode === Mode.all) {
                    if (data) {
                        return data[0].totalCount
                    }
                } else {
                    return rows.length
                }
            })()

            return {
                rows,
                count: count
            }
        } catch (e) {
            return {
                count: 0,
                rows: []
            }
        }
    }, [])

    const [selectedRow, setSelectedRow] = useState<IGridListItem>()
    const [eventContentFormModal, showHideEventContentFormModal] = useModal()
    const [eventModal, showHideEventModal] = useModal()
    const [confirmDeleteEventContentModal, showHideConfirmDeleteEventContentModal] = useModal()
    const [confirmDeleteEventModal, showHideConfirmDeleteEventModal] = useModal()
    const [confirmDuplicateModal, showHideConfirmDuplicateModal] = useModal()
    const [inviteBlastModal, showHideInviteBlastModal] = useModal()
    const [addGroupCoachingFormModal, showHideAddGroupCoachingFormModal] = useModal()
    const [eventToDuplicate, setEventToDuplicate] = useState<IEventModelDocument>()
    const [groupCoachingToEdit, setGroupCoachingToEdit] = useState<IGroupCoachingModelDocument>()
    const [addEventContentModal, showHideAddEventContentModal] = useModal()
    const [addToCalendarModal, showHideAddToCalendarModal] = useModal()
    const [helpModal, showHideHelpModal] = useModal()

    // Need to honor the globalCommunityContext.branchId selection and display all EventContent records
    // regardless of whether or not the seleted branchId has an event tied to that EC record.
    // 20240503 TB - [MA20-4363] ADs are tired of seeing event content records (e.g., workshops) that are unrelated to them. So, we are now enabling respectGlobalCommunityFilter instead.
    const communityFilter: IFilter = {
        id: 'community-filter',
        value: globalCommunityContext?.branchId ? [globalCommunityContext?.branchId.toString(), '0'] : undefined, 
        operator: 'in',
        property: 'branchId',
        enabled: Boolean(globalCommunityContext?.branchId),
        hidden: true
    }

    // Support linking to a particular event by appending "#view=EVENT_ID" to /event-management URL
    const location = useLocation()
    useEffect(() => {
        const parsedHash = locationHashToObject(location.hash)
        if (parsedHash.view) editEvent(parsedHash.view)
    }, [location.hash])

    const editEvent = useCallback(async (eventId: string) => {
        const { data: eventToEdit } = await makeHttpRequestWithUi({
            request: EventMgmtApi.apiEventMgmtEventEventIdGet(parseInt(eventId)),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error retrieving event to edit.',
        })

        const { data: selectedEventContent } = await makeHttpRequestWithUi({
            request: EventMgmtApi.apiEventMgmtEventContentEventContentIdGet(eventToEdit.eventContentId),
            disableSuccessToast: true,
            toastErrorMessage: 'Enountered an error retrieving event content record.',
        })

        setState(_state => ({ ..._state, eventToEdit, selectedEventContent }))
        showHideEventModal(true)
    }, [])

    const eventTypeGridAction:IGridAction = {
        id: 'eventType',
        label: '',
        dropdown: {
            options: [
                { label: 'All Event Types', value: '0' }, 
                { label: 'Group Learning', value: EVENT_TYPES.GroupLearning.toString() }, 
                { label: 'Webinar', value: EVENT_TYPES.Webinar.toString() },
                { label: 'Workshop', value: EVENT_TYPES.Workshop.toString() },
            ],
            onChange: async (option: ISelectFieldOption | ISelectFieldOption[] | null) => {
                if (isSelectFieldOption(option)) {
                    //console.log('EVENT_TYPES[option.label]', EVENT_TYPES[option.label as keyof typeof EVENT_TYPES])
                    // @ts-ignore
                    const results = option.value === '0' ? [EVENT_TYPES.GroupLearning, EVENT_TYPES.Webinar, EVENT_TYPES.Workshop] : [EVENT_TYPES[option.label.replace(/\s/g, "")]]
                    await setState(_state => ({
                        ..._state,
                        // @ts-ignore
                        eventTypeIds: results,
                    }))
                }
            },
            value: state.eventTypeIds.length > 1 ? '0' : state.eventTypeIds[0].toString(),
        }
    }

    const timeRangeGridAction:IGridAction = {
        id: 'timeRange',
        label: '',
        dropdown: {
            options: [
                { label: 'Show Current', value: Mode.upcoming }, // 20220527 TB - Used to be 'Show Upcoming', but decided to change to 'Show Current', since past events are also returned for 'current' Event Content records.
                { label: 'Previous 6 Mos.', value: Mode["6mos"] },
                { label: 'Previous 12 Mos.', value: Mode["12mos"] },
                { label: 'Show All', value: Mode.all },
            ],
            onChange: (option: ISelectFieldOption | ISelectFieldOption[] | null) => isSelectFieldOption(option) && setState(_state => ({
                ..._state,
                // @ts-ignore
                mode: Mode[option.value],
            })),
            value: state.mode,
        }
    }

    const groupLearningTopicsGridAction:IGridAction = {
        id: 'topics',
        label: 'Group Learning Topics',
        onClick: () => navigate(`/${Path["group-learning-topics"]}`),
        sortOrder: 1,
    }

    // 20230705 TB - This action was previously only available to super users.
    const addUpcomingGridAction = {
        id: 'newEventContent',
        label: currentUser?.bSuperUser ? 'Add Event Content' : 'Add Group Learning',
        onClick: () => currentUser?.bSuperUser ? showHideAddEventContentModal(true) : showHideAddGroupCoachingFormModal(true),
    }

    const getCellStyling = useCallback((dividend: GridListItemValueType, divisor: GridListItemValueType): React.CSSProperties => {
        if (typeof dividend !== 'number' || typeof divisor !== 'number') return {}

        let value = dividend / divisor * 100
        if (divisor === 0) value = 100
        if (dividend === 0) value = 0

        let backgroundColor = '#dc354520'
        if (value > 30) backgroundColor = '#ffc10720'
        if (value > 60) backgroundColor = '#28a74520'

        return {
            width: `${value}%`,
            backgroundColor,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            ...value === 0 ? { marginLeft: 5 } : { paddingLeft: 5, }
        }
    }, [])

    // Don't allow user to change event type, if it was passed in.
    const gridActionsInitializer = [eventTypeGridAction, timeRangeGridAction, addUpcomingGridAction]
    
    if (currentUser?.bSuperUser) gridActionsInitializer.push(groupLearningTopicsGridAction)

    const initialGridState: IGridState = {
        ...defaultGridState,
        usingLocalData: true,
        disabledPagination: state.mode !== Mode.all,
        userSessionStateKey: GridUserInteractionStateKey.EventManagement,
        queryState: {
            ...defaultGridState.queryState,
            //filters: [communityFilter] // 20240503 TB - [MA20-4363] ADs are tired of seeing event content records (e.g., workshops) that are unrelated to them. So, we are now enabling respectGlobalCommunityFilter instead.
        },
        gridActions: gridActionsInitializer,
        rowGroupActions: {
            actions: [
                {
                    id: 'editEventContent',
                    action: async ({ rowGroupId }) => {
                        window.open(`/event-content/${rowGroupId}`)
                    },
                    // action: async ({ rowGroupId }) => {
                    //     const { data } = await makeHttpRequestWithUi({
                    //         request: EventMgmtApi.apiEventMgmtEventContentEventContentIdGet(parseInt(rowGroupId)),
                    //         disableSuccessToast: true,
                    //         toastErrorMessage: 'Enountered an error retrieving event content record to edit.',
                    //     })
                    //     setState(_state => ({ ..._state, selectedEventContent: data }))
                    //     showHideEventContentFormModal(true)
                    // },
                    icon: <PencilIcon />,
                    title: 'Edit Event Content',
                    disabled: (rowGroupId, gridState) => {
                        const rowGroupRows = gridState.rows.filter(o => o.rowGroup?.id === rowGroupId)
                        if(currentUser?.bSuperUser) {
                            // Super users can edit any event type.
                            return false
                        }
                        else if (rowGroupRows.some(o => o.values.eventTypeId == EVENT_TYPES.GroupLearning)) {
                            // Group Learning is accessible to anyone
                            return false
                        }
    
                        return true
                    }
                },
                {
                    id: 'addEvent',
                    action: async ({ rowGroupId, gridState }) => {
                        const { data } = await makeHttpRequestWithUi({
                            request: EventMgmtApi.apiEventMgmtEventContentEventContentIdGet(parseInt(rowGroupId)),
                            disableSuccessToast: true,
                            toastErrorMessage: 'Enountered an error retrieving event content record.',
                        })
                        setState(_state => ({ ..._state, selectedEventContent: data }))
    
                        const rowGroupRows = gridState.rows.filter(o => o.rowGroup?.id === rowGroupId)
                        if (rowGroupRows.some(o => o.values.eventTypeId == EVENT_TYPES.GroupLearning)) {
                            showHideAddGroupCoachingFormModal(true)
                        }
                        else {
                            showHideEventModal(true)
                        }
                    },
                    icon: <CalendarPlusIcon />,
                    title: 'Add Event',
                    disabled: (rowGroupId, _gridState) => {
                        const rowGroupRows = _gridState.rows.filter(o => o.rowGroup?.id === rowGroupId)
                        if (rowGroupRows.some(o => o.values.isLegacyEvent)) return true
                        if (rowGroupRows.some(o =>
                            !o.values.eventContentIsActive ||
                            (o.values.eventTypeId === EVENT_TYPES.Webinar && (!currentUser?.bSuperUser))
                        )) return true
    
                        // We only allow one community per Group Learning record. To enforce this
                        // we will only allow you to add events if you are filtered to a community.
                        // Since you won't be able to see the event if you are not filtered to the proper community.
                        const _filters = [...gridState.queryState.filters || []]
                        const _communityFilter = _filters.find(o => o.id === communityFilter.id)
                        
                        if (_communityFilter) {
                            if (!_communityFilter.value || _communityFilter.value < 1) return true
                        }
    
                        return false
                    },
                },
            ],
            nestedActions: [
                {
                    id: 'manageInviteBlasts',
                    action: async ({ rowGroupId }) => {
                        const { data } = await makeHttpRequestWithUi({
                            request: EventMgmtApi.apiEventMgmtEventContentEventContentIdGet(parseInt(rowGroupId)),
                            disableSuccessToast: true,
                            toastErrorMessage: 'Enountered an error retrieving event content record to edit.',
                        })
                        setState(_state => ({ ..._state, selectedEventContent: data }))
                        showHideInviteBlastModal(true)
                    },
                    icon: <EnvolpeIcon />,
                    title: 'Manage Invite Blasts',
                    disabled: (rowGroupId, _gridState) => {
                        const rowGroupRows = _gridState.rows.filter(o => o.rowGroup?.id === rowGroupId)
                        if (rowGroupRows.some(o => o.values.isLegacyEvent)) return true
                        if (rowGroupRows.some(o =>
                            !o.values.eventContentIsActive ||
                            (o.values.eventTypeId === EVENT_TYPES.Webinar && !currentUser?.bSuperUser)
                        )) return true
    
                        return false
                    },
                },
                {
                    id: 'linkToCommunityEvents',
                    action: async ({ rowGroupId }) => {                       
                        if (globalCommunityContext?.branchId) {
                            window.open(`${MINISTRY_PORTAL_ROOT_URL}/events/PublicUpcomingEvents/${rowGroupId}/${globalCommunityContext?.branchId}`, '_blank')?.focus()
                            //return <a href={`https://ministries.mif.org/events/PublicUpcomingEvents/${rowGroupId}/${toArray(_communityFilter.value)[0]}`} target="_blank" className='d-flex'><GlobeIcon /></a>
                        }
                    },
                    icon: <GlobeIcon />,
                    title: 'View Public Listing',
                    disabled: (rowGroupId, _gridState) => { 
                        if (!globalCommunityContext?.branchId || globalCommunityContext?.branchId < 1) return true
                        const rowGroupRows = _gridState.rows.filter(o => o.rowGroup?.id === rowGroupId)
                        if (rowGroupRows.some(o => o.values.isLegacyEvent)) return true
                        if (rowGroupRows.some(o => o.values.eventTypeId === EVENT_TYPES.Webinar)) return true
                        if (rowGroupRows.some(o => o.values.eventTypeId === EVENT_TYPES.SmallGroupSession)) return true

                        return false
                    },
                },
                {
                    id: 'duplicateEventContent',
                    action: async ({ rowGroupId }) => {
                        window.open(`/group-coaching-duplicate/${rowGroupId}`, '_blank')?.focus()
                    },
                    icon: <LayersIcon />,
                    title: 'Duplicate',
                    disabled: (rowGroupId, _gridState) => {
                        const rowGroupRows = _gridState.rows.filter(o => o.rowGroup?.id === rowGroupId)
                        if (rowGroupRows.some(o => o.values.isLegacyEvent)) return true
                        if (rowGroupRows.some(o =>
                            o.values.eventTypeId !== EVENT_TYPES.GroupLearning
                        )) return true
    
                        return false
                    },
                },
                {
                    id: 'deleteEventContent',
                    action: async ({ rowGroupId }) => {
                        const { data } = await makeHttpRequestWithUi({
                            request: EventMgmtApi.apiEventMgmtEventContentEventContentIdGet(parseInt(rowGroupId)),
                            disableSuccessToast: true,
                            toastErrorMessage: 'Enountered an error retrieving event content record to edit.',
                        })
                        setState(_state => ({ ..._state, selectedEventContent: data }))
                        showHideConfirmDeleteEventContentModal(true)
                    },
                    title: 'Delete Event Content',
                    icon: <XSquareIcon />,
                    disabled: (rowGroupId, _gridState) => !currentUser?.bSuperUser || _gridState.rows.some(o => o.values.status == EventContentStatus.activated && o.values.eventContentId?.toString() == rowGroupId),
                    hidden: () => !Boolean(currentUser?.bSuperUser)
                }
            ]
        },
        rowActions: {
            editEvent: {
                id: 'editEvent',
                action: async (options) => options.e.stopPropagation(),
				icon: <PencilIcon />,
                tooltipText: 'View Event',
                url: (row) => {
                    //@ts-ignore
                    const url = getEventUrl(row.values.eventTypeId, row.values.eventContentId, row.values.eventId)
                    return url
                }
            },
            viewRegistrants: {
                id: 'viewRegistrants',
                // action: async ({ row }) => {
                //     const { data: eventToEdit } = await makeHttpRequestWithUi({
                //         request: EventMgmtApi.apiEventMgmtEventEventIdGet(parseInt(row.id)),
                //         disableSuccessToast: true,
                //         toastErrorMessage: 'Encountered an error retrieving event to edit.',
                //     })

                //     const { data: selectedEventContent } = await makeHttpRequestWithUi({
                //         request: EventMgmtApi.apiEventMgmtEventContentEventContentIdGet(eventToEdit.eventContentId),
                //         disableSuccessToast: true,
                //         toastErrorMessage: 'Enountered an error retrieving event content record.',
                //     })

                //     setState(_state => ({ ..._state, eventToEdit, selectedEventContent, eventFormTab: EventFormTab.registrants }))
                //     showHideEventModal(true)
                // },
                action: async (options) => options.e.stopPropagation(),
                //@ts-ignore
				icon: <PeopleIcon />,
                tooltipText: 'Registrants',
                disabled: row => row.values.status === EventStatus.draft,
                url: (row) => {
                    //@ts-ignore
                    return getEventUrl(row.values.eventTypeId, row.values.eventContentId, row.values.eventId) + '#registrants'
                },
            },
            viewEventListing: {
                id: 'viewEventListing',
                action: async (options) => options.e.stopPropagation(),
                icon: <GlobeIcon />,
                tooltipText: 'View Public Event Listing',
                disabled: (row) => row.values.startTime ? dayjs(row.values.startTime.toString()).isBefore(dayjs(), 'day') : false,
                url: (row) => {
                    if (row.values.startTime && dayjs(row.values.startTime.toString()).isBefore(dayjs(), 'day')) {
                        return '#'
                    }
                    else {
                        return row.values.publicEventUrl?.toString() || '#'
                    }
                },
            },
            addToCalendar: {
                id: 'addToCalendar',
                action: async ({ row }) => {
                    setSelectedRow(row)
                    showHideAddToCalendarModal(true)
                },
                icon: <CalendarEventIcon />,
                tooltipText: 'Add To My Calendar',
                url: '#'
            },
            deleteEvent: {
                id: 'deleteEvent',
                action: async ({ row }) => {
                    setSelectedRow(row)
                    showHideConfirmDeleteEventModal(true)
                },
                tooltipText: 'Delete Event',
                icon: <SquareDeleteIcon />,
                disabled: (row) => {
                    if (row.values.isLegacyEvent) return true

                    return (row.values.registrantCount || 0) > 0
                }
            },
            duplicateEvent: {
                id: 'duplicateEvent',
                action: async ({ row }) => {
                    setSelectedRow(row)

                    const { data } = await makeHttpRequestWithUi({
                        request: EventMgmtApi.apiEventMgmtEventEventIdGet(parseInt(row.id)),
                        disableSuccessToast: true,
                        toastErrorMessage: 'Encountered an error retrieving event to edit.',
                    })
                    setEventToDuplicate(data)
                    showHideConfirmDuplicateModal(true)
                },
                tooltipText: 'Duplicate Event',
                icon: <LayersIcon />,
                disabled: (row) => {
                    return !!row.values.isLegacyEvent
                }
            }
        },
        columns: [
            {
                property: 'eventContentTitle',
                type: 'string',
                width: 125,
                allowFilters: true,
                title: 'Title',
                render: DefaultGridCellDisplay,
                hide: true
            },
            {
                property: 'eventType',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'Type',
                render: DefaultGridCellDisplay,
                filterOptions: EVENT_CONTENT_TYPES,
                hide: true
            },
            {
                property: 'startTime',
                type: 'date',
                width: 210,
                allowFilters: true,
                title: 'Start Time',
                render: (col, row) => {
                    //@ts-ignore
                    const url = getEventUrl(row.values.eventTypeId, row.values.eventContentId, row.values.eventId)
                return <><a href={url}>{dayjs(row.values.startTime?.toString()).format('MM/DD/YYYY hh:mm a')} {row.values.timezoneGeneric}</a> <a href={url} target='_blank' style={{marginLeft: "5px"}}><BoxArrowUpRight /></a></>},
            },
            {
                property: 'endTime',
                type: 'date',
                width: 135,
                allowFilters: true,
                title: 'End Time',
                render: DefaultGridCellDisplay,
                dateFormatOverride: 'MM/DD/YYYY hh:mm a',
                hide: true
            },
            {
                property: 'branchAbbr',
                type: 'string',
                width: 90,
                allowFilters: true,
                title: 'Community',
                render: DefaultGridCellDisplay,
                filterOptions: activeBranches.map(b => ({ label: `${b.branchAbbr && b.branchAbbr.trim()} (${b.branchName})`, value: `${b.branchAbbr}` })),
            },
            {
                property: 'siteName',
                type: 'string',
                width: 200,
                allowFilters: true,
                title: 'Site',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'status',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'Status',
                align: 'center',
                render: DefaultGridCellDisplay,
                conditionalCellCSSFormatting: (row) => {
                    if (row.values.status === IGroupCoachingEventStatus.activated) return 'bg-success-7'
                    if (row.values.status === IGroupCoachingEventStatus.canceled) return 'bg-warning-7'
                    if (row.values.status === IGroupCoachingEventStatus.deleted) return 'bg-danger-7'
                    if (row.values.status === IGroupCoachingEventStatus.draft) return 'bg-secondary-7'
                    return ''
                },
                filterOptions: Object.keys(IGroupCoachingEventStatus).map(o => ({ value: o, label: o }))
            },
            {
                property: 'seatCount',
                type: 'number',
                width: 75,
                allowFilters: false,
                title: 'Seat Count',
                render: DefaultGridCellDisplay,
                rowGroupTotal: true,
                hide: true
            },
            {
                property: 'orgsRegisteredCount',
                type: 'number',
                width: 75,
                allowFilters: false,
                title: 'Orgs Registered',
                description: 'Total number of unique organizations actively registered for this event.',
                render: DefaultGridCellDisplay,
                rowGroupTotal: true,
            },
            {
                property: 'orgsAttendedCount',
                type: 'number',
                width: 75,
                allowFilters: false,
                title: 'Orgs Attended',
                description: 'Total number of unique organizations registered for this event that actually attended.',
                render: DefaultGridCellDisplay,
                rowGroupTotal: true,
            },
            {
                property: 'registrantCount',
                type: 'number',
                width: 75,
                allowFilters: false,
                title: 'Registrants',
                description: 'Total number of individual registrants actively registered for this event. Color indicates percentage of available seats that were filled.',
                render: (_, row) =>
                    <div style={getCellStyling(row.values.registrantCount, row.values.seatCount)}>
                        {row.values.registrantCount}
                    </div>,
                conditionalCellCSSFormatting: () => 'no-padding-cell',
                rowGroupTotal: true,
            },
            {
                property: 'attendeeCount',
                type: 'number',
                width: 75,
                allowFilters: true,
                title: 'Attendees',
                description: 'Total number of individual registrants who attended this event. Color indicates percentage of registrants who converted to attendees.',
                render: (_, row) =>
                    <div style={getCellStyling(row.values.attendeeCount, row.values.registrantCount)}>
                        {row.values.attendeeCount}
                    </div>,
                conditionalCellCSSFormatting: () => 'no-padding-cell',
                rowGroupTotal: true,
            },
            {
                property: 'evalCount',
                type: 'number',
                width: 75,
                allowFilters: false,
                disableSort: true,
                title: 'Eval Count',
                render: DefaultGridCellDisplay,
                rowGroupTotal: true,
            },
            {
                property: 'nextInviteEmailScheduleDate',
                type: 'date',
                width: 165,
                allowFilters: true,
                title: 'Next Invite Blast',
                render:  (_, row) => {
                    if (row.values.nextInviteEmailScheduleDate) {
                        if (row.values.nextInviteEmailScheduleDate == '0001-01-01T00:00:00') return "N/A"
                        const nextInviteEmailScheduleDate = dayjs(row.values.nextInviteEmailScheduleDate?.toString())
                        return nextInviteEmailScheduleDate ? nextInviteEmailScheduleDate.format('MM/DD/YYYY hh:mm a') : "N/A"
                    }
                    return '';
                },
                description: 'Date that next invitation email blast is scheduled for this community. If no blasts are upcoming, displays the date of the most recent blast.'
            },
            {
                property: 'afterEventHours',
                type: 'number',
                width: 65,
                allowFilters: true,
                title: 'Hours',
                description: 'Hours logged for this appointment.',
                render: DefaultGridCellDisplay,
                rowGroupTotal: true
            },
            {
                property: 'grid_actions',
                type: 'actions',
                width: 125,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center'
            },

        ],
        dataSource,
        rowSelectEnabled: false,
        respectGlobalCommunityFilter: true,
        infinitePaging: true,
        rowDoubleClicked: async (row) => {
            //@ts-ignore
            navigate(getEventUrl(row.values.eventTypeId, row.values.eventContentId, row.values.eventId))
        },
    }

    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, state)

    useEffect(() => {
        GroupCoachingTopicApi.apiGroupCoachingTopicGet()
            .then(results => {
                setState(_state => ({ ..._state, topics: results.data }))
            })

        makeHttpRequestWithUi({
            request: GroupCoachingTopicApi.groupCoachingTopicTypesGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error retrieving group learning topic types.'
        })
            .then(results => {
                setState(_state => ({ ..._state, topicTypes: results.data as { id: number, name: string }[] }))
            })
        makeHttpRequestWithUi({
            request: PresentersApi.apiPresentersGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the list of presenters.'
        })
            .then(results => {
                setState(_state => ({ ..._state, presenters: results.data.filter(o => o.bActive) }))
            })
    }, [])

    // useEffect(() => {
    //     const topicTypeColumn = gridState.columns.find(o => o.property === 'groupCoachingTopicTypeId')
    //     if (topicTypeColumn && state.topicTypes.length !== topicTypeColumn?.filterOptions?.length) {
    //         gridActions.updateColumn({
    //             ...topicTypeColumn,
    //             filterOptions: state.topicTypes.map(o => ({ value: o.id.toString(), label: o.name }))
    //         })
    //     }
    // }, [state.topicTypes, gridState, gridActions])

    const previousMode = useRef(state.mode)
    useEffect(() => {
        if (previousMode.current !== state.mode) {
            fetchEvents(state.mode, state.eventTypeIds)
            //@ts-ignore
            gridActions.updateGridAction({ ...timeRangeGridAction, dropdown: { ...timeRangeGridAction.dropdown, value: state.mode } })
            if (state.mode === Mode.all) {
                gridActions.toggleUsingLocalData(false)
            } else {
                gridActions.toggleUsingLocalData(true)
            }

            gridActions.updateGridAction({ ...addUpcomingGridAction, buttonClass: state.mode === Mode.upcoming ? undefined : 'invisible' })
            gridActions.updateGridAction({ ...eventTypeGridAction, disabled: state.mode === Mode.all })

            /* 
                https://missionincrease.atlassian.net/browse/MA20-2183
                Toggle show/hide of the Title and Type columns based on whether we're in the "All" mode or not.
            */
            const titleColumn = gridState.columns.find(o => o.property === 'eventContentTitle')
            const typeColumn = gridState.columns.find(o => o.property === 'eventType')
            if (titleColumn && typeColumn) {
                if (state.mode === Mode.all) {
                    titleColumn.hide = false
                    typeColumn.hide = false
                } else {
                    titleColumn.hide = true
                    typeColumn.hide = true
                }

                gridActions.updateColumns([titleColumn, typeColumn])
            }

        }
        previousMode.current = state.mode
    }, [state.mode])


    const previousEventTypeIds = useRef(state.eventTypeIds)
    // Initial fetch
    useEffect(() => {
        if (previousEventTypeIds.current !== state.eventTypeIds) {
            fetchEvents(state.mode, state.eventTypeIds)
            //@ts-ignore
            gridActions.updateGridAction({ ...eventTypeGridAction, dropdown: { ...eventTypeGridAction.dropdown, value: state.eventTypeIds.length > 1 ? '0' : state.eventTypeIds[0].toString() }})
        }

        previousEventTypeIds.current = state.eventTypeIds
    }, [state.eventTypeIds])

    useEffect(() => {
        fetchEvents(state.mode, state.eventTypeIds)
    }, [])

    useEffect(() => {
        gridActions.doFetch()
    }, [state.events])

    // Update the communityFilter whenver the branchId selection changes.
    useEffect(() => {
        const _filters = [...gridState.queryState.filters || []]
        const _communityFilter = _filters.find(o => o.id === communityFilter.id)
        
        if (_communityFilter) {
            _communityFilter.value = globalCommunityContext?.branchId ? [globalCommunityContext?.branchId.toString(), '0'] : undefined
            _communityFilter.enabled = Boolean(globalCommunityContext?.branchId)
            gridActions.updateColumnFilters(_filters)
        }
    }, [globalCommunityContext?.branchId]);

    return (
        <>
            <Helmet>
                <title>Event Management</title>
            </Helmet>

            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <div className='m-2 d-flex align-items-center justify-content-between'>
                <div className='m-2 d-flex align-items-center'><CalendarEventIcon style={{ width: '25px', height: '25px' }} />
                    <h3 className='ml-2'>Event Management</h3></div>
                    <div className='d-flex'><button type='button' className='btn btn-info btn-sm icon-right ml-1' onClick={() => showHideHelpModal(true)}>Help <InfoCircleIcon /></button></div>
                </div>
                {state.mode === Mode.upcoming ?
                    <p className='ml-2'>
                        'Show Current' is a list of all upcoming events, and events for which the Event Content record's General Quarter is current or upcoming.
                    </p>
                    : 
                    null
                }
                {currentUser?.bSuperUser ?
                    <p className='ml-2'>
                        You are a super user; therefore you can add and edit Event Content records for Workshops and Webinars. Non-super users can only do this for Group Learning event types.<br />
                    </p>
                    :
                    null
                }

                <Grid state={gridState} actions={gridActions} />
            </div>

            <Modal
                {...eventContentFormModal}
                modalTitle={!!state.selectedEventContent ?
                    <div>
                        Edit {state.selectedEventContent.title} 
                        {state.selectedEventContent.isSeries && <> - Series</>}
                        &nbsp;({state.selectedEventContent.eventContentId})
                        {state.selectedEventContent.status === EventContentStatus.activated ? <span className='badge badge-success ml-2'>Activated</span> : null}
                        {state.selectedEventContent.status === EventContentStatus.draft ? <span className='badge badge-info ml-2'>Draft</span> : null}
                        {state.events.filter(x => x.eventContentId === state.selectedEventContent?.eventContentId).some(x => x.isLegacyEvent) ? <span className='badge badge-danger ml-2'>Legacy</span> : null}
                    </div>
                    :
                    'Add Event Content'
                }
                size='xxl'
                minHeightPercent={100}

                _onModalHidden={() => {
                    setState(_state => ({ ..._state, selectedRow: undefined, selectedEventContent: undefined }))
                    fetchEvents(state.mode, state.eventTypeIds)
                }}

                dismissible={false}
            >
                {eventContentFormModal.show && state &&
                    <EventContentForm
                        eventContentToEdit={state.selectedEventContent}
                        setEventContentToEdit={eventContentToEdit => setState(_state => ({ ..._state, selectedEventContent: eventContentToEdit }))}
                        presenters={state.presenters}
                    />
                }
            </Modal>

            <Modal
                {...addEventContentModal}
                modalTitle='Add Event Content' 
                size='md'
                dismissible={false}
            >
                {addEventContentModal.show && state &&
                    <div>
                        <p>What type of event would you like to create?</p>
                        <div className="d-flex flex-row justify-content-start">
                            <button type='button' className='btn btn-primary' onClick={() => {
                                showHideAddGroupCoachingFormModal(true)
                                showHideAddEventContentModal(false)
                            }}>Group Learning</button>
                            <button type='button' className='btn btn-primary ml-5' 
                                onClick={() => {
                                showHideEventContentFormModal(true)
                                showHideAddEventContentModal(false)
                            }}>Workshop / Webinar</button>
                        </div>
                    </div>
                }
            </Modal>

            <Modal
                {...addGroupCoachingFormModal}
                modalTitle='New Group Learning Event' 
                //{`New ${state.selectedTopicType || ''} Event`}
                size='xxl'
                minHeightPercent={100}

                _onModalHidden={() => {
                    setSelectedRow(undefined)
                    setState(_state => ({ ..._state, selectedEventContent: undefined, selectedTopicType: undefined, eventFormTab: undefined }))
                    setGroupCoachingToEdit(undefined)
                }}

                dismissible={false}
            >
                {addGroupCoachingFormModal.show && state &&
                    <GroupCoachingForm
                        topicTypes={state.topicTypes}
                        topics={state.topics}
                        eventToEdit={groupCoachingToEdit}
                        presenters={state.presenters}
                        setEventToEdit={setGroupCoachingToEdit}
                        afterSave={async () => {
                            fetchEvents(state.mode, state.eventTypeIds)
                            showHideAddGroupCoachingFormModal(false)
                        }}
                        closeModal={() => showHideAddGroupCoachingFormModal(false)}
                        selectedEventContent={state.selectedEventContent}
                        setSelectedTopicType={selectedTopicType => setState(_state => ({ ..._state, selectedTopicType }))}
                    />
                }
            </Modal>

            <Modal
                {...inviteBlastModal}
                modalTitle={`Manage Invite Blasts for ${activeCommunity?.branchAbbr}`}
                size='xl'

                _onModalHidden={() => {
                    setState(_state => ({...state, selectedEventContent: undefined}))
                }}

                dismissible={false}
            >
                { inviteBlastModal.show && state.selectedEventContent && activeCommunity ?
                    <EventInviteBlastsForm
                        eventContentSummary={state.events.filter(x => x.eventContentId === state.selectedEventContent?.eventContentId)[0]}
                        branchId={activeCommunity?.branchId || 0}
                        branchAbbr={activeCommunity?.branchAbbr || ''}
                        afterSave={() => {
                            fetchEvents(state.mode, state.eventTypeIds)
                            showHideInviteBlastModal(false)
                        }}
                        onCancel={() => showHideInviteBlastModal(false)}
                    />
                    :
                    <>You are a super user and must select which community you wish to manage.</>
                }
            </Modal>

            <Modal
                {...eventModal}
                modalTitle={!!state.eventToEdit ?
                    <div>
                        Edit Event
                        {state.eventToEdit.startDateTime && <span>&nbsp;-&nbsp;{dayjs(state.eventToEdit.startDateTime?.toString()).format('M/D/YYYY')}</span>}
                        {state.eventToEdit.trainingArea && <span>&nbsp;-&nbsp;{state.eventToEdit.trainingArea}</span>}
                        {state.eventToEdit.siteName && <span>&nbsp;-&nbsp;{state.eventToEdit.siteName}</span>}
                        &nbsp;({state.eventToEdit.eventId})
                        {state.eventToEdit.status === EventStatus.activated ? <span className='badge badge-success ml-2'>Activated</span> : null}
                        {state.eventToEdit.status === EventStatus.draft ? <span className='badge badge-info ml-2'>Draft</span> : null}
                        {state.eventToEdit.status === EventStatus.canceled ? <span className='badge badge-warning ml-2'>Canceled</span> : null}
                        {state.eventToEdit.isLegacyEvent ? <span className='badge badge-danger ml-2'>Legacy</span> : null}
                    </div>
                    :
                    'Add Event'
                }
                size='fullscreen'

                _onModalHidden={() => {
                    setState(_state => ({ ..._state, selectedRow: undefined, eventToEdit: undefined, selectedEventContent: undefined, eventFormTab: undefined }))
                    fetchEvents(state.mode, state.eventTypeIds)
                }}

                dismissible={false}
            >
                {eventModal.show && state.presenters && state.selectedEventContent &&
                    <EventForm
                        eventContent={state.selectedEventContent}
                        eventToEdit={state.eventToEdit}
                        setEventToEdit={eventToEdit => setState(_state => ({ ..._state, eventToEdit }))}
                        presenters={state.presenters}
                        initialTab={state.eventFormTab}
                    />
                }
            </Modal>

            <Modal
                {...confirmDeleteEventContentModal}
                modalTitle='Confirm Delete Event Content'
                footer={
                    <>
                        <button type='button' className='btn btn-secondary' onClick={() => showHideConfirmDeleteEventContentModal(false)}>Cancel</button>
                        <button
                            type='button'
                            className='btn btn-danger'
                            onClick={async () => {
                                if (state.selectedEventContent) {
                                    await makeHttpRequestWithUi({
                                        request: EventMgmtApi.apiEventMgmtEventContentEventContentIdDelete(state.selectedEventContent.eventContentId),
                                        toastSuccessMessage: 'Successfully deleted event content record.',
                                        toastErrorMessage: 'Encountered an error deleting event content record.',
                                    })
                                    showHideConfirmDeleteEventContentModal(false)
                                    fetchEvents(state.mode, state.eventTypeIds)
                                }
                            }}
                        >
                            Delete
                        </button>
                    </>
                }
                _onModalHidden={() => setState(_state => ({ ..._state, selectedEventContent: undefined }))}
            >
                <p>Are you sure you want to delete this event content record?</p>
                <p>This will delete the event content record <b>and</b> all event records attached to it.</p>
                <p>This action cannot be undone.</p>
            </Modal>
            <Modal
                {...confirmDeleteEventModal}
                modalTitle='Confirm Delete Event'
                footer={
                    <>
                        <button type='button' className='btn btn-secondary' onClick={() => showHideConfirmDeleteEventModal(false)}>Cancel</button>
                        <button
                            type='button'
                            className='btn btn-danger'
                            onClick={async () => {
                                if (selectedRow) {
                                    await makeHttpRequestWithUi({
                                        request: EventMgmtApi.apiEventMgmtEventEventIdDelete(parseInt(selectedRow.id)),
                                        toastSuccessMessage: 'Successfully deleted event record.',
                                        toastErrorMessage: 'Encountered an error deleting event record.',
                                    })
                                    showHideConfirmDeleteEventModal(false)
                                    fetchEvents(state.mode, state.eventTypeIds)
                                }
                            }}
                        >
                            Delete
                        </button>
                    </>
                }
                _onModalHidden={() => setSelectedRow(undefined)}
            >
                <p>Are you sure you want to delete this event?</p>
                <p>This action cannot be undone.</p>
            </Modal>
            <Modal
                {...confirmDuplicateModal}
                modalTitle='Duplicate Event'
                // footer={
                //     <>
                //         <button type='button' className='btn btn-secondary' onClick={() => showHideConfirmDuplicateModal(false)}>Cancel</button>
                //         <button
                //             type='button'
                //             className='btn btn-primary'
                //             onClick={async () => {
                //                 if (selectedRow) {
                //                     await makeHttpRequestWithUi({
                //                         request: EventMgmtApi.apiEventMgmtEventEventIdDuplicatePost(parseInt(selectedRow.id)),
                //                         toastSuccessMessage: 'Successfully duplicated event.',
                //                         toastErrorMessage: 'Encountered an error duplicating event.',
                //                     })
                //                     showHideConfirmDuplicateModal(false)
                //                     gridActions.doFetch()
                //                 }
                //             }}
                //         >
                //             Duplicate
                //         </button>
                //     </>
                // }
                _onModalHidden={() => {
                    showHideConfirmDuplicateModal(false)
                    setSelectedRow(undefined)
                    setEventToDuplicate(undefined)
                }}
                size='xxl'
            >
                {eventToDuplicate ?
                    <EventDuplicateForm
                        eventToDuplicate={eventToDuplicate}
                        onCancel={() => {
                            showHideConfirmDuplicateModal(false)
                            setSelectedRow(undefined)
                            setEventToDuplicate(undefined)
                        }}
                        afterSave={() => {
                            showHideConfirmDuplicateModal(false)
                            setSelectedRow(undefined)
                            setEventToDuplicate(undefined)
                            fetchEvents(state.mode, state.eventTypeIds)
                        }
                        } />
                    :
                    null}
            </Modal>
            <Modal
				{...helpModal}
				modalTitle='Events Help'
				size={'lg'}
			>
                <h4>When do reminder emails go out for each event type?</h4>
                <ul>
                    <li><strong>Coaching Appointment</strong>
                        <ol>
                            <li>Reminder email goes out 1 day prior to the event.</li>
                        </ol>
                    </li>
                    <li><strong>Workshops:</strong>
                        <ol>
                            <li>Reminder email goes out 4 business days prior to the event.</li>
                        </ol>
                    </li>
                    <li><strong>Webinars:</strong>
                        <ol>
                            <li>Reminder email goes out 7 days prior to the event.</li>
                            <li>Reminder email goes out 30-minutes prior to the event.</li>
                        </ol>
                    </li>
                    <li><strong>Group Learning:</strong>
                        <ol>
                            <li>Reminder email goes out 7 days prior to the event.</li>
                            <li>Reminder email goes out 30-minutes prior to the event.</li>
                        </ol>
                    </li>
                </ul>
                <p></p>
                <p></p>
            </Modal>
            {selectedRow && <EventAddToMyCalendar 
                modalProps={addToCalendarModal}
                eventContentTitle={selectedRow.values.eventContentTitle?.toString() || ''}
                eventId={parseInt(selectedRow.id)}
                startTime={dayjs(selectedRow.values.startTime?.toString()).format('MM/DD/YYYY hh:mm a')}
                timezone={selectedRow.values.timezoneGeneric?.toString() || ''} />}
        </>
    )
}