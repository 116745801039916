import { useEffect } from 'react'
import React, { useMemo } from "react";
import { useAuth, hasAuthParams } from "react-oidc-context";
import { AppStoreProvider } from './app-store-provider'
import { globalHistory, Router, navigate } from '@reach/router'
import { SignOut } from './components/sign-out'
import { Dashboard } from './components/dashboard'
import { AppLoadingOverlay } from './components/loading'
import { Toasts } from './components/toasts'
import { Alerts } from './components/alerts'
import { MinistryInfoAreaDirectorEvalSummaryPrint } from './components/ministry-info-ad-eval-summary-print'
import { TrainerEvalsPrint } from './components/trainer-evals-print'
import { AppOnWindowFocus } from './components/app-on-window-focus'
import { EventRegistrantsSignInSheet } from './components/download-sign-in-sheet'
import * as Fathom from 'fathom-client';
import { GrantRequestSummaryReportPrint } from './components/grant-request-summary-report-print'
import { GrantRequestAgreementPrint } from './components/grant-request-agreement-print'
import { Loading } from './components/loading';
import { IS_PRODUCTION } from './constants'
import { initiateLogin } from './services/auth-config'; 
import { ImpersonationBar } from './components/impersonation-bar';
import { LoginErrorPage } from './components/login-error-page';
import BaseLayout from './components/base-layout'

// import { Maintenance } from './maintenance'	// Uncomment this line to show Maintenance Page

const App = () => {

	// return <Maintenance />					// Uncomment this line to show Maintenance Page

	const redirectUrl = useMemo(() => {
		const params = new URLSearchParams(window.location.search);
		return params.get('url') || '';
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, []);
	
	const auth = useAuth();
	const [hasTriedSignin, setHasTriedSignin] = React.useState(false);
	
	// automatically sign-in
	React.useEffect(() => {
		if (!hasAuthParams() &&
			!auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
			!hasTriedSignin
		) {
			initiateLogin(); // Used to store url prior to redirect to IdP
			auth.signinRedirect();
			setHasTriedSignin(true);
			//navigate(redirectUrl);
		}
	}, [auth, hasTriedSignin]);

	// Hack to remove Bootstrap modal backdrop when back button is pressed
	// https://getbootstrap.com/docs/4.6/components/modal/#usage
	// https://stackoverflow.com/questions/20462350/bootstrap-modal-backdrop-stays-when-i-use-the-back-button-from-the-browser
	useEffect(() => {
		window.addEventListener('popstate', () => {
			const modalBackDrop = document.getElementsByClassName('modal-backdrop')
			Array.from(modalBackDrop).forEach(o => o.remove())
		})
	}, [])

	// Add Freshdesk Help Widget
	useEffect(() => {
		// Create the first script tag
		const script1 = document.createElement('script');
		script1.innerHTML = `
			window.fwSettings={
				'widget_id':154000001808,
				'locale': 'en'
			};
			!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
		`;
		document.head.appendChild(script1);

		// Create the second script tag
		const script2 = document.createElement('script');
		script2.src = 'https://widget.freshworks.com/widgets/154000001808.js';
		script2.async = true;
		script2.defer = true;
		document.head.appendChild(script2);

		const freshworksWidget = (window as any).FreshworksWidget;
		freshworksWidget('hide');

		// Cleanup function to remove the scripts when the component unmounts
		return () => {
			document.head.removeChild(script1);
			document.head.removeChild(script2);
		};
	}, []);
	
	useEffect(() => {
		if (IS_PRODUCTION) {
			// https://www.npmjs.com/package/fathom-client?activeTab=explore
			Fathom.load('YJWXTSPS', {
			includedDomains: ['admin.mif.org']
			});

			// https://github.com/reach/router/issues/262
			return globalHistory.listen(({ action }) => {
				if (action === 'PUSH') {
					Fathom.trackPageview();
				}
			})
		}
	}, []);

	if (auth.isLoading) {
		return <div className={`loading-overlay fixed }`}><Loading /></div>;
	}
	
	//auth.error = new Error('login_required');
	if (auth.error) {		
		if(auth.error.message == 'login_required') {	
			return <BaseLayout className='center'> 
				<div style={{textAlign: 'center'}}>
					<h1>GrowthTrack</h1>
					<p></p>
					<p>Your user has been logged out...</p>
					<p>Please login again</p>
					<button className='btn btn-primary' onClick={() => navigate('/')}>Log in</button>
				</div>
			</BaseLayout>;
		}

		return <BaseLayout className='center'>
			<div style={{textAlign: 'center'}}>
			<h1>GrowthTrack</h1>
			<p></p>
			<p>Unable to authorize user... {auth.error?.message}</p>
			<p>Please try logging in again or contact <a href="mailto:support@missionincrease.org">support</a></p>
			<button className='btn btn-primary' onClick={() => navigate('/')}>Log in</button>
			</div>
			</BaseLayout>;
	}
	
	if (auth.isAuthenticated) {				
		return (
			<div>
				<AppStoreProvider>
					<AppOnWindowFocus />
					<AppLoadingOverlay />
					<ImpersonationBar />
					<Toasts />
					<Alerts />
			
					<Router>
						<SignOut path='logout' />
						<MinistryInfoAreaDirectorEvalSummaryPrint path='ad-summary-print/:ministryId' />
						<TrainerEvalsPrint path='trainer-evals-print/:eventContentId' />
						<EventRegistrantsSignInSheet path='event-sign-in-sheet/:eventId' />
						<GrantRequestSummaryReportPrint path='grant-request-summary-report-print/:grantRequestGuid' />
						<GrantRequestAgreementPrint path='grant-request-agreement-print/:grantId' />
						<LoginErrorPage path='login-error' />
						<Dashboard default />
					</Router>
				</AppStoreProvider>
			</div>
		)
	}
	return <div>Authentication Issue</div>;
}

export default App